
import React, { useEffect,useRef, useState } from 'react';
import { createChart, LineStyle } from 'lightweight-charts';
import axios from 'axios';
import io from 'socket.io-client';
React.StrictMode = React.Fragment;


function App() {
 
  return (
    <div className="App">
    </div>
  );
}

export default App;
