import React from 'react'
import NotAdmin from '../components/notAdmin'

const analytics = () => {
  return (
    <div>
        <NotAdmin/>
    </div>
  )
}

export default analytics
